<template>
  <div class="welcome flex_column" style="height: 95vh;justify-content: space-between;">
    <div class="pair_mask" v-show="isPairing">
      
    </div>
    <div class="flex_row" style="justify-content: flex-end;">
      <div class="flex_row" style="justify-content: space-evenly; width: 80%;">
        <div class="flex_row_center" v-if="driverCfg.demo">
          <span>输入密码</span>
          <el-input
          v-model="password"
          show-password
          class="password_class">
          </el-input>
          <el-button 
          @click="handlePasswordClick"
          class="ok_class"
          lang="OK">确定</el-button>
        </div>


        <div v-if="driverCfg.demo">
          <span>设备类型：</span>
          <select 
          v-model="device">
            <option 
            v-for="item in deviceOption" 
            :key="item.value" 
            :value="item.value">
          {{item.option}}</option>
          </select>
        </div>


        <div v-if="driverCfg.demo">
          <span>Sensor类型：</span>
          <select 
          v-model="selectSensor">
            <option 
            v-for="item in sensorOptions" 
            :key="item.value" 
            :value="item.value">
          {{item.option}}</option>
          </select>
        </div>
      </div>
         <!-- 语言选择下拉框 -->
      <select 
      v-model="language" 
      @change="handleLanguageChange">
        <option 
          v-for="item in languageOptions" 
          :key="item.value" 
          :value="item.value">
          {{ item.option }}
        </option>
      </select>      
    </div>

    <!-- 欢迎和介绍 -->
    <div class="flex_column" style="align-items: center;">
      <p class="title_font" lang="Welcome">欢迎使用Control HUB WEB</p> 
      <p class="subtitle_font" lang="SelectDevice">在这里您可以设置您的设备，包含按键、sensor、灯光及其它配置;</p>
      <p class="subtitle_font" lang="SupportSystem">适配 Mac OS/Window/Linux 等主流操作系统</p>
      <p class="subtitle_font" lang="SupportBrowser">仅支持Google Chrome，Microsoft Edge，Opera等浏览器访问</p>
      <el-button 
      @click="handleConnetClick" 
      :disabled="isSupportConnect == false"
      class="connect_button"
      lang="Connect">连接设备+</el-button>
      <span class="operation_font" lang="OperationTips">操作提示：</span>
    </div>

    <!-- <div 
    class="flex_row"
    style="width: 100%; justify-content: center;">
      <div   
      v-show="historyDevices.length != 0"    
      v-for="(item, index) in historyDevices"
      :key="index" 
      @click="handleDeviceReconnectClick(item)"
      class="history_device">
      <div class="reportRate_img"></div>
      <div class="isWired_img"></div>
      <div class="flex_center" style="height: 100%; flex-direction: column;">
        <span>Name:{{item.device.productName}}</span>
        <span>Online:{{item.online}}</span>
      </div>

      </div>
    </div> -->

    <!-- 操作提示和版本 -->
     <div class="flex_column">
      <div class="flex_row" style="justify-content: space-between; align-items: center;">
        <div style="display: flex; align-items: baseline;">
          <span class="pair_box" lang="PairButtonTips">新接收器对码:</span>
          <span class="pair_tips_font" style="margin-left: 10px;" lang="PairTips">敲击空格键进入配对，在弹窗中选择需要对码的接收器，回车确认（30秒）</span>
        </div>

        <div class="flex_row" style="align-items: center;">
          <span class="version_font" lang="Version">版本：</span>
          <span class="version_font" style="margin-right: 20px;">{{version}}</span>
        </div>
      </div>

      <div v-show="showICP" class="flex_row" style="justify-content: center; align-items: baseline; margin-top: 30px;">
        <span > 
        Control Hub |<a href='https://beian.miit.gov.cn/'  class="web_span"> 粤ICP备06015730号-2</a> </span>
      </div>
     </div>
    <tips ref="tipsDialog" :text="tipsText"></tips>
    <pair-dialog ref="pairDialog"></pair-dialog> 
  </div>
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle';
  import Tips from '@/components/Dialog/Tips.vue'
  import PairDialog from '@/components/Dialog/PairDialog.vue';

  export default {
    components:{
      Tips,
      PairDialog,
    },
    data() {
      return {
        languageOptions:[],
        languages:[],
        language:"zh-CN",
        isSupportConnect:true,
        driverCfg:{},
        version:"v1.0.0",
        lang:{},
        tipsText:'',
        device:"mouse",
        deviceOption:[
          {
            value:"mouse",
            option:"mouse"
          },
          {
            value:"keyboard",
            option:"keyboard"
          }
        ],
        normalPassword:'',
        compxPassword:"Compx123",
        password:"",
        sensor:{},
        sensorOptions:[],
        selectSensor:"3395",
        showICP:true,
        isPairing:false,
        historyDevices:[],
        reconnectIntervalId:null
      }
    },
    methods: {
      handleLanguageChange() { 
        var i = 0; 
        for(i = 0;i < this.languageOptions.length;i++) {
          if(this.language == this.languageOptions[i].value) {
            break;
          }
        }
 
        this.$bus.$emit("languageOptionsChange",this.language);
        this.$bus.$emit("languageChange",this.languages[i]);
      },
      handlePasswordClick() {
        if((this.password == this.compxPassword) || (this.password == this.normalPassword)) {
          localStorage.setItem('password',JSON.stringify(this.password));
          this.isSupportConnect = true;
        }
        else {
          this.isSupportConnect = false;
        }
      },
      //获取浏览器信息，HID服务仅支持“Chrome”，“Edge”和“Opera”，不支持“safari”和“FireFox”
      getBrowserInfo() {
        let types = ['edg','firefox','chrome','safari','opera '];
        let userAgent = navigator.userAgent.toLocaleLowerCase();
        var res = [];

        types.forEach(element => {
            if(userAgent.indexOf(element)>0){
                let rule= `${element}`+"\\/([\\d.]+)";
                res.push(element);
                res.push(userAgent.match(rule)[1]);
            }
        });

        if(res.indexOf('chrome') >-1 && res.indexOf('safari') >-1){
            if(res.length===4){
                let temp=[];
                temp.push('chrome');
                temp.push(res[res.indexOf('chrome')+1]);
                res = temp;
            }else{
            res.splice(res.indexOf('chrome'),2);
            res.splice(res.indexOf('safari'),2);
            }
        }

        if(res[0] === "chrome" || 
          res[0] === "edg" ||
          res[0] === "opera")
          {
            this.isSupportConnect = true;
          }
          else 
            this.isSupportConnect = false;
      },
      async handleKeyDown(event) {   
        if((this.isPairing == false) && (this.isSupportConnect)) {
          if (event.code === 'Space') {
            // 执行某些操作
            this.isPairing = true;
            console.log("pairDialog open",event);
            var connect = await this.requestDevice();

            if(connect) {
              this.$refs.pairDialog.visible = true;
              console.log("dey down",this.driverCfg.demo,this.driverCfg);
              if(!this.driverCfg.demo) {
                HIDHandle.Set_Pair_CID(this.driverCfg.mouse[0].cid)
              }

              // console.log("visiMode",this.$refs.pairDialog.visitMode);
            }
            else {
              this.isPairing = false;
            }
          }
        }
      },
      async requestDevice() {
        var connect = false;
        var driverCfg = this.driverCfg;
        var filters = [];
        var type = this.device;

        //Demo连接设备
        if(driverCfg.demo || driverCfg.visit) {
          connect = await HIDHandle.Request_Device(filters);
        }
        else {
          var pid = [];
          var devicePid = [driverCfg.pid.mouse,driverCfg.pid.keyboard];
          var mousePid = [];
          var keyboardPid = [];

          for(var j = 0;j < devicePid.length;j++) {
            var tmp = devicePid[j];
            for(var i = 0;i < tmp.wireless.length;i++) {
              pid.push(tmp.wireless[i]);

              if(j == 0) {
                mousePid.push(tmp.wireless[i]);
              }
              else if(j == 1) {
                keyboardPid.push(tmp.wireless[i]);
              }
            }
            for(var i = 0;i < tmp.wired.length;i++) {
              pid.push(tmp.wired[i]);

              if(j == 0) {
                mousePid.push(tmp.wired[i]);
              }
              else if(j == 1) {
                keyboardPid.push(tmp.wired[i]);
              }
            }  
          }

          for(let i = 0;i < pid.length;i++)
          {
            var filter = {
              vendorId: Number.parseInt(driverCfg.vid),
              productId: Number.parseInt(pid[i]),
            }
            filters.push(filter);
          }
          console.log("filters",filters);
          connect = await HIDHandle.Request_Device(filters);

          if(connect) {
            //当前设备是鼠标还是键盘
            for(var i = 0;i < mousePid.length;i++) {
              if(Number.parseInt(mousePid[i]) == HIDHandle.devicePID) {
                type = "mouse";
                break;
              }
            }

            for(var i = 0;i < keyboardPid.length;i++) {
              if(Number.parseInt(keyboardPid[i]) == HIDHandle.devicePID) {
                type = "keyboard";
                break;
              }
            } 
            HIDHandle.deviceInfo.type = type;     
          }
        }
        
        return connect;
      },
      async deviceConnect(info) {
        if(this.driverCfg.visit) {
          var path = "demo.png";
          var sensor = "3950";
          var deviceCfg = this.driverCfg.mouse[0].cfg[0];
          if(HIDHandle.deviceInfo.type == "mouse") {
            HIDHandle.deviceInfo.mouseCfg.sensor.type = deviceCfg.sensor;
            HIDHandle.deviceInfo.mouseCfg.sensor.cfg = this.sensor[sensor] || null;
            HIDHandle.deviceInfo.mouseCfg.keysCount = deviceCfg.keys.length;

            this.$bus.$emit("setMouseDefaultCfg",deviceCfg);
          }

          path = HIDHandle.deviceInfo.type + "/" + path;

          this.$bus.$emit("setDevicePath",path);
          this.$bus.$emit('backToHome',false);            
        }
        else {
          var found = false;
          var device;
          var deviceCfg;
          var path = "demo.png";
          var sensor = "3395";

          if(HIDHandle.deviceInfo.type == "mouse") {
            device = this.driverCfg.mouse;    
          }
          else {
            device = this.driverCfg.keyboard;        
          }

          if(this.driverCfg.demo) {
            deviceCfg = device[0].cfg[0];
            sensor = this.selectSensor;
            found = true;
          }
          else {                 
            //cid或者mid为0说明设备不在线或者没有配对，需要提醒设备处于离线状态
            if((info.cid != 0) && (info.mid != 0)) {
              var cid;

              for(var i = 0;i < device.length;i++) {
                if(info.cid === device[i].cid) {
                  for(var j = 0;j < device[i].cfg.length;j++) {
                    if(info.mid === device[i].cfg[j].mid) {
                      found = true;
                      console.log("connect info:",info,device[i].cfg[j]);
                      deviceCfg = device[i].cfg[j];
                      cid = device[i].cid;
                      sensor = device[i].cfg[j].sensor;
                      break;
                    }
                  }              
                }
              }
              
              //未找到符合要求的cid，mid，提醒暂不支持该设备
              if(found == false) {
                this.tipsText = this.lang.DialogDeviceUnsupport;
                this.$refs.tipsDialog.visible = true;
              }
              else {
                //设置设备的图片
                path = cid.toString(16).padStart(2, '0') +
                deviceCfg.mid.toString(16).padStart(2, '0') + '.png';
              }
            }
            else {
              this.tipsText = this.lang.DialogOffline;
              this.$refs.tipsDialog.visible = true;
            }                         
          }

          if(found) {
            if(HIDHandle.deviceInfo.type == "mouse") {
              HIDHandle.deviceInfo.mouseCfg.sensor.type = sensor;
              HIDHandle.deviceInfo.mouseCfg.sensor.cfg = this.sensor[sensor] || null;
              var max = 0;
              for(var i = 0;i < deviceCfg.keys.length;i++) {
                max = deviceCfg.keys[i].index > max ? deviceCfg.keys[i].index : max;
              }

              HIDHandle.deviceInfo.mouseCfg.keysCount = max + 1;

              HIDHandle.deviceInfo.mouseCfg.defaultLongDistance = deviceCfg.longDistance;
              HIDHandle.Set_DriverOnline(deviceCfg.driverOnline);
              console.log("driverOnline",deviceCfg,HIDHandle.driverOnlineFlag);
              this.$bus.$emit("setMouseDefaultCfg",deviceCfg);
            }

            path = HIDHandle.deviceInfo.type + "/" + path;

            this.$bus.$emit("setDevicePath",path);
            this.$bus.$emit('backToHome',false);

            await HIDHandle.Device_Connect();

            window.removeEventListener('keydown', this.handleKeyDown);

            this.$bus.$emit("updateMaxReportRate",HIDHandle.deviceInfo.maxReportRate);
          }
        }        
      },
      // 选择需要操作的HID设备
      async handleConnetClick() {
        if(this.isSupportConnect) {
          var connect = await this.requestDevice();

          if(connect) {
            var info = await HIDHandle.Get_Device_Info();

            await this.deviceConnect(info);
          } 
        }
      },
      async handleDeviceReconnectClick(item) {
        console.log("handleDeviceReconnectClick",item);
        await HIDHandle.Device_Reconnect(item.device);

        var info = {
          cid : item.cid,
          mid : item.mid,
        }
        await this.deviceConnect(info);
      },
      async getHistpryDevicesInfos() {
        // if(this.reconnectIntervalId == null) {
        //   this.reconnectIntervalId = setInterval(async() => {
        //     this.historyDevices = await HIDHandle.Get_HistoryDevicesInfo();
        //   },500)
        // }

        console.log("getHistpryDevicesInfos");
      }
    },
    created() {
      this.getHistpryDevicesInfos();
      this.getBrowserInfo();

      this.$bus.$on("setGlobalSensor",sensor => {
        this.sensor = sensor;
        var keys = Object.keys(sensor);
        this.sensorOptions = [];
        for(var i = 0;i < keys.length;i++) {
          if(sensor[keys[i]].hasOwnProperty('range')) {
            var tmp = {
              value:keys[i],
              option:keys[i]
            }
            this.sensorOptions.push(tmp);
          }
        }
      });     

      this.$bus.$on("setGlobalLanguages",(options,languages) => {
        this.languageOptions = options;
        this.languages = languages; 

        //如果只有一种语言，默认为该语言
        if(options.length == 1) {
          this.language = this.languageOptions[0].value;
        }
        else {
          //查找应用（App）是否保存了上次选择的语言
          var item = localStorage.getItem('locale');
          if(item) {
            var tmp = JSON.parse(item);
            this.language = tmp;
            console.log("language:",this.language,this.languages,this.languageOptions);
          }
          else
          {
            //获取浏览器的语言
            if(navigator.language) {
              var found = false;
              for(var i = 0;i < this.languageOptions.length;i++) {
                if((this.languageOptions[i].value == navigator.language) ||
                  (this.languageOptions[i].value == navigator.language.toLowerCase())) {
                    this.language = this.languageOptions[i].value;
                    found = true;
                    break;
                  }       
              }

              //如果语言包里面有浏览器的语言，就调用该语言
              if(found == false) 
                this.language = "en";
            }
            else
              this.language = "en";
          }
        }

        this.handleLanguageChange();
      });

      this.$bus.$on("setDriverCfg",driverCfg => {
        this.driverCfg = driverCfg;
        this.version = driverCfg.version; 

        if(this.driverCfg.vid == '') {
          this.isSupportConnect = false;
          this.showICP = true;
        }
        else {
          this.showICP = false;
        }

        if(driverCfg.demo) {
          const now = new Date();
          const year = now.getFullYear();
          const moth = (now.getMonth() + 1).toString().padStart(2,'0');
          const day = now.getDate().toString().padStart(2,'0');
          const time = `${year}-${moth}-${day}`;
          this.normalPassword = btoa(time);
          console.log("time:",time,this.normalPassword);
          if((this.password != this.compxPassword) && (this.password != this.normalPassword)) {
            this.isSupportConnect = false;
          }
        }
      });

      this.$bus.$on("languageOptionsChange",value => {
        this.language = value;
        localStorage.setItem('locale',JSON.stringify(value));
      });

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined'){
          this.lang = lang;
          let elements = document.querySelectorAll('[lang]');

          // 遍历每个需要翻译的元素
          elements.forEach(element => {
          let id = element.getAttribute('lang');  
          if (lang[id]) {
              element.innerText = lang[id]; // 使用翻译后的文本替换原来的文本
            }
          });
        }
      });  
      
      //设备断开或者第一次打开网页进入主页
      this.$bus.$on('backToHome',async (value) => {
        if(value) {
          window.removeEventListener('keydown', this.handleKeyDown);
          window.addEventListener('keydown', this.handleKeyDown);
          console.log("backToHome");
          this.getHistpryDevicesInfos();
        }
        else {
          if(this.reconnectIntervalId != null) {
            clearInterval(this.reconnectIntervalId);
            this.reconnectIntervalId = null;
          }
        }
      });
    },
    mounted() {
      window.addEventListener('keydown', this.handleKeyDown);

      this.$bus.$on("pairDialogOpen",state => {
        if(state == false)
          this.isPairing = state;
      }); 

      var item = localStorage.getItem('password');
      if(item) {
        var tmp = JSON.parse(item);
        this.password = tmp;
      }
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown);
      this.$bus.$off('setGlobalSensor');
      this.$bus.$off('setGlobalLanguages');
      this.$bus.$off('setDriverCfg');
      this.$bus.$off('languageChange');
      this.$bus.$off('languageOptionsChange');
      this.$bus.$off('pairDialogOpen');
    }
  }
</script>

<style lang="less" scoped>
:deep(.el-input__inner) {
  color:var(--home-font-color);
}

:deep(.el-input .el-input__clear) {
  color:var(--home-font-color);
}


.web_span {
  cursor: pointer;
  text-decoration: none
}

.password_class {
  width: calc(var(--font-size) * 15);
  height: calc(var(--font-size) * 2);

}

.ok_class {
  width: calc(var(--font-size) * 3);
  height: calc(var(--font-size) * 2);
  margin-left: calc(var(--font-size) * 0.5);
}


</style>