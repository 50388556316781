<template>
  <div id="app" class="flex_center" :style="backgroundStyle" >
    <div v-show="isLoaded">
      <div v-show="noFound">
        <not-found></not-found>
      </div>
      <div v-show="noFound == false">
        <home  v-show="isHome"></home> 
        <mouse v-show="!isHome"></mouse>
      </div>
    </div>
  </div>
</template>

<script>
  import Home from './views/Home'
  import Mouse from './views/Mouse'
  import NotFound from './views/NotFound'

  import HIDHandle from './assets/js/HIDHandle';

  export default {
    name: 'App',
    components: {
      Home,  
      Mouse,  
      NotFound      
    },
    data() {
      return {
        noFound:false,
        driverCfg:null,
        isHome:true,
        bgImage:'./img/home_bg.png',
        screenWidth: '',
        screenHeight: '',
        widthRatio:1,
        heightRatio:1,
        currentTheme:"light",
        isLoaded:false
      }
    },
    methods: {
      changeMouseImageSize() {
        const root = document.documentElement;
        var ratio = this.widthRatio;
        if(this.widthRatio > this.heightRatio) {
          ratio = this.heightRatio;
        }

        root.style.setProperty('--mouse-img-width' , ratio * 760 + 'px');
        root.style.setProperty('--mouse-img-height' , ratio * 630 + 'px');
        this.$bus.$emit("mouseImageResize",ratio);
      },
      updateTheme() {
        //切换主题色（light/dark）
        const root = document.documentElement;
        var themePara;
        //亮色主题
        if(this.currentTheme == 'light') {
          themePara = this.driverCfg.light;
          root.style.setProperty('--option-color' , 'rgba(50,160,127,0.5)');
        }
        else//暗色主题
        {
          themePara = this.driverCfg.dark;         
          root.style.setProperty('--option-color' , 'rgba(0,66,227,0.5)');
        }

        root.style.setProperty('--theme-color' , themePara.theme);
        root.style.setProperty('--font-color' , themePara.font);
        root.style.setProperty('--border-color' , themePara.border);
        root.style.setProperty('--key-inner-color' , themePara.keyInner);

        root.style.setProperty('--home-font-color', this.driverCfg.home.font);
        root.style.setProperty('--home-theme-color', this.driverCfg.home.theme);
        var path = window.location.href+ "img/dialog_" + this.currentTheme + ".png)";
        console.log("path",path);
        root.style.setProperty('--dialog-image-path', "url(" + path);
        
        this.bgImage = window.location.href + 'img/home_bg.png';
        localStorage.setItem('theme',JSON.stringify(this.currentTheme));
      },   
    },
    created() {
      this.$bus.$on("setDriverCfg",driverCfg => {
        this.driverCfg = driverCfg;
        if(typeof driverCfg.visit == "undefined") {
          HIDHandle.Set_Visit_Mode(false);
        }
        else 
          HIDHandle.Set_Visit_Mode(driverCfg.visit);

        this.updateTheme();
      });

      this.$bus.$on('themeChange',theme => {
        this.currentTheme = theme;
        if(this.driverCfg != null) {
          this.updateTheme();
        }
      });

      this.$bus.$on("setGlobalLanguages",(options,languages) => {
        setTimeout(() => {
          this.isLoaded = true;
        },100);
      });
    },
    mounted() {
      this.$bus.$on("noFound",value => {
        this.noFound = value;
      }); 

      //设备断开或者第一次打开网页进入主页
      this.$bus.$on('backToHome',value => {
        this.isHome = value;
      });

      this.screenWidth = document.body.clientWidth;
      this.screenHeight = document.body.clientHeight;
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth;
          this.screenHeight = document.body.clientHeight;
        })();
      };
    },
    watch: {
      "screenWidth" : {
        handler() {
          const root = document.documentElement;

          var ratio = this.screenWidth / 1920 * 0.9;
          this.widthRatio = ratio;
          var layoutPadding = 40;
          //显示器width/1920的比例
          root.style.setProperty('--screen-width-ratio' , ratio);
          this.$bus.$emit("widthResize",ratio);
          //主页内容的宽度
          root.style.setProperty('--home-width' , this.screenWidth - ratio *  layoutPadding * 2 + 'px');
          //主界面内容的宽度
          root.style.setProperty('--main-width' , this.screenWidth - ratio *  layoutPadding * 2 + 'px');

          //主页标题的大小
          root.style.setProperty('--title-font-size' , ratio * 55 + 'px');
          //主页副标题，介绍文本的大小
          root.style.setProperty('--subtitle-font-size' , ratio * 36 + 'px');
          //操作提示文本大小
          root.style.setProperty('--tips-font-size' , ratio * 22 + 'px');
          //全文字体大小
          root.style.setProperty('--font-size' , ratio * 20 + 'px');

          //主页主题按钮的大小
          root.style.setProperty('--theme-button-size' , ratio * 32 + 'px');

          //下拉框宽度
          root.style.setProperty('--select-width' , ratio * 200 + 'px');
          //数字输入框宽度
          root.style.setProperty('--input-number-width' , ratio * 160 + 'px');

          //    --battery-width:83px;
          //--charging-width:36px;
          root.style.setProperty('--battery-width' , ratio * 83 + 'px');
          root.style.setProperty('--charging-width' , ratio * 36 + 'px');
          //logo图片高度
          root.style.setProperty('--logo-img-height' , ratio * 100 + 'px');

          //鼠标图片大小



          //按键选择卡的大小 
          root.style.setProperty('--cascader-width' , ratio * 270 + 'px');

          //滑条按钮
          root.style.setProperty('--slider-button-width' , ratio * 8 + 'px');
          //DPI滑条长度 
          root.style.setProperty('--dpi-slider-width' , ratio * 1200 + 'px');

          //dpi按钮的大小
          root.style.setProperty('--dpi-select-width' , ratio * 80 + 'px');
          root.style.setProperty('--dpi-unselect-width' , ratio * 80 + 'px');

          //报告率按钮宽度 
          root.style.setProperty('--reportRate-button-width' , ratio * 105 + 'px');

          //checkbox大小
          root.style.setProperty('--checkbox-width' , ratio * 24 + 'px');

          //switchx大小
          root.style.setProperty('--switch-width' , ratio * 40 + 'px');
          root.style.setProperty('--moving-switch-width' , ratio * 60 + 'px');

          //radio大小
          root.style.setProperty('--radio-button-width' , ratio * 16 + 'px'); 

          //DPI灯效滑条长度
          root.style.setProperty('--dpiEffect-slider-width' , ratio * 250 + 'px');

          //宏列表尺寸
          root.style.setProperty('--table-width' , ratio * 380 + 'px');
          root.style.setProperty('--table-height' , ratio * 600 + 'px'); 
          
          //宏底下按钮的宽度
          root.style.setProperty('--macro-button-width' , ratio * 175 + 'px'); 

          //宏录制按钮和输入框宽度
          root.style.setProperty('--macro-record-width' , ratio * 280 + 'px'); 

          //灯光通用宽度：灯光模式下拉框，亮度和速度的滑条，自定义颜色色条长度
          root.style.setProperty('--light-normal-width' , ratio * 400 + 'px'); 
          //预览颜色块的宽度
          root.style.setProperty('--preview-color-width' , ratio * 90 + 'px');
          //预设颜色块的宽度 
          root.style.setProperty('--preset-color-width' , ratio * 38 + 'px'); 

        },
        deep: true
      },
      "screenHeight": {
        handler() {
          const root = document.documentElement;
          var ratio = this.screenHeight / 910;
          this.$bus.$emit("heightResize",ratio);
          this.heightRatio = ratio;

          //    --battery-width:83px;
          //--charging-width:36px;
          root.style.setProperty('--battery-height' , ratio * 40 + 'px');
          root.style.setProperty('--charging-height' , ratio * 14 + 'px');
        },
        deep: true
      },
      "widthRatio": {
        handler() {
          this.changeMouseImageSize();
        },
        deep: true
      },
      "heightRatio": {
        handler() {
          this.changeMouseImageSize();
        },
        deep: true
      },
    },
    beforeDestroy() {
      this.$bus.$off('setDriverCfg');
      this.$bus.$off('backToHome');
      this.$bus.$off('themeChange');
      this.$bus.$off('setGlobalLanguages');
    },
    computed: {
      backgroundStyle() {
      return {
        backgroundImage: `url(${this.bgImage})`,
      };
    },
    }
}
</script>

<style lang="less">
  * {
    margin: 0;
    padding: 0;
  }

  :root {
    //屏幕宽度比例，基值1920px
    --screen-width-ratio:1;
    --screen-height-ratio:1;

    //主页宽度
    --home-width:1200px;
    //主界面宽度
    --main-width:1200px;

    //所有使用到的字体大小
    //主页标题的大小
    --title-font-size:55px;
    //主页副标题，介绍文本的大小
    --subtitle-font-size:36px;
    //操作提示文本大小
    --tips-font-size:22px;
    //全文字体大小
    --font-size:20px;

    //颜色
    //字体颜色：亮色主题为#333333，暗色主题为#ffffff
    //按钮默认字体颜色
    --home-font-color:#333333;
    --font-color: #333333;
    //主题颜色:亮色主题为#32A07E，暗色主题为#0062E3
    //按钮默认背景颜色
    --home-theme-color:#32A07E;
    --theme-color: #32A07E;

    //控件样式
    //控件大小
    //下拉框宽度
    --select-width:200px;
    --option-color:rgba(50,160,127,0.5);
    --input-number-width:130px;
    --radio-button-width:16px;

    //控件颜色
    //鼠标悬停按键背景颜色
    --button-hover-color:  #A3B7BF; 
    //按钮边框颜色
    --border-color: #777777;
    --margin-left: 15px;
    --margin-top: 25px;

    --disabled-color:#bfbfbf;


    //主页主题按钮的大小
    --theme-button-size:32px;
    --battery-width:83px;
    --battery-height:40px;
    --charging-width:36px;
    --charging-height:14px;

    //logo图片的高度
    --logo-img-height:100px;
    --mouse-img-width:760px;
    --mouse-img-height:630px;

    --cascader-width:270px;

    --slider-button-width:8px;
    --dpi-slider-width:1200px;

    --dpi-select-width:95px;
    --dpi-unselect-width:75px;

    --reportRate-button-width:105px;
    --checkbox-width:24px;
    --switch-width:50px;
    --moving-switch-width:60;

    --dpiEffect-slider-width:320px;

    --table-width:380px;
    --table-height:600px;

    --macro-button-width:175px;
    --macro-record-width:260px;

    --light-normal-width:500px;
    --preview-color-width:104px;
    --preset-color-width:43px;

    --key-inner-color:#123456;
  }

</style>
